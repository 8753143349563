/* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@font-face {
    font-family: 'worksans';
    src: url('./assets/font/worksans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    background-color: #F4F6F9 !important;
    font-family: 'workans';
    color: #000;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
}

.main-blue {
    color: #02D1FF;
}

.blue {
    color: #1D2B50 !important;
}
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
